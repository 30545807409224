import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { Product, UppercaseProduct } from '@uc/web/shared/data-models';
import { EnvironmentApiService } from '@uc/utilities';

@Injectable({
	providedIn: 'root',
})
export class BookEventBtnApiService {
	private _http = inject(HttpClient);
	private _apiSrv = inject(EnvironmentApiService);

	trackEventClick(uniId: number, product: Product, eventId: number | null = null) {
		const uppercaseProduct = product.toUpperCase() as UppercaseProduct;

		const params = {
			university_id: uniId,
			open_day_id: eventId,
			product: uppercaseProduct,
		};

		lastValueFrom(
			this._http.post(`${this._apiSrv.API_URL}leads/book-open-day`, params),
		)
			.then(console.log)
			.catch(console.error);
	}
}
