@if (externalUrl()) {
	<a
		data-cy="visit-website-btn-cy"
		(click)="onVisitWebsiteClick(); onVisitWebsiteConversion()"
		[attr.href]="externalUrl()"
		target="_blank"
		[ngClass]="customStyles()"
		class="inline-block blue-button focus:outline-none whitespace-nowrap"
		rel="sponsored noopener"
		data-test-id="visit-website-btn"
	>
		{{ buttonText() }}
	</a>
}
